<template>
    <div class="action-manager-survey">
        <template>
            <div class="messages">
                <!-- <div class="message-text" v-html="msg" /> -->
                <div class="message-button">
                    <button @click="onClickCta" class="btn btn-primary" v-html="content.cta" />
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import { isStackRoute } from '@/router/stack-router'

export default {
    name: 'ActionManagerServey',
    props: ['message'],
    computed: {
        chat() {
            return this.$store.getters.chat
        },
        content() {
            return this.$mustParse(this.message.content) || {}
        },
        msg() {
            // const title = this.content.title.split('.')
            // return `${title[0]}. <br> ${title[1]}`
            return this.content.msg || ''
        },
    },
    methods: {
        async onClickCta() {
            this.$nativeBridge.postMessage({
                action: 'sendFirebaseEvent',
                value: {
                    category: 'Click_Manager_Survey_CTA',
                },
            })
            if (!this.content.action) return

            if (this.content.action.includes('http://') || this.content.action.includes('https://')) {
                this.$nativeBridge.postMessage({
                    action: 'openInAppBrowser',
                    value: this.content.action,
                })
                return
            }

            isStackRoute(this.content.action)
                ? this.$stackRouter.push({ name: this.content.action })
                : this.$router.push({ name: this.content.action })
        },
    },
}
</script>

<style lang="scss" scoped>
.action-manager-survey {
    .messages {
        // font-size: 16px;
        // line-height: 1.33;
        text-align: left;
        // color: black;
        // @include f-medium;
        .message-text {
            box-shadow: none;
            border: none;
        }

        .message-button {
            margin: 12px 0;
            padding: 0 12px;
            button {
                width: 100%;
                @include f-regular;
                font-size: 14px;
            }
        }
    }
}
</style>
